import "core-js/modules/es.array.push.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      routeMeta: {},
      showTeamAction: false,
      leftPopupVisible: false
    };
  },
  created() {
    this.routeMeta = this.$route.meta;
  },
  computed: {
    ...mapGetters(["getTeamList", "getCurrTeam"]),
    teamList() {
      return this.getTeamList.map(item => {
        return {
          name: item.team_name,
          id: item.id,
          color: item.is_use === 1 ? "#ee0a24" : ""
        };
      });
    }
  },
  methods: {
    logout() {
      this.$store.commit("CLEAR_USER_INFO");
      this.$router.push("/login");
    },
    onTeamSelect(actions) {
      this.$confirmDialog({
        title: "提示",
        message: `确定需要切换至团队：${actions.name} ？`,
        beforeClose: action => {
          if (action === "cancel") {
            return true;
          } else {
            this.$axios.switchTeamHandle({
              team_id: actions.id
            }).then(res => {
              if (res.code === 1) {
                this.$store.dispatch("getUserTeamList");
              }
            });
            return true;
          }
        }
      });
    },
    openLeftPopup() {
      this.$store.commit("SET_LEFT_POPUP_VISIBLE", true);
    },
    openTeamSelectAction() {
      this.showTeamAction = true;
    },
    onClickRight() {
      const theme = this.$store.state.theme;
      const newTheme = theme === "light" ? "dark" : "light";
      this.$store.commit("SET_THEME", newTheme);
    }
  }
};